// extracted by mini-css-extract-plugin
export var popoutNav = "default-module--popoutNav--1sCF8";
export var active = "default-module--active--1J3TB";
export var hamburgerWrapper = "default-module--hamburgerWrapper--1WA0-";
export var logo = "default-module--logo--384nU";
export var layout = "default-module--layout--2fOqA";
export var navigation = "default-module--navigation--Rpf_x";
export var navLinks = "default-module--navLinks--1Y0zj";
export var img = "default-module--img--2_Ya9";
export var logoTextStack = "default-module--logoTextStack--3GaZe";
export var logoImpact = "default-module--logoImpact--1E-Au";
export var footer = "default-module--footer--3Ibr2";
export var footerRow = "default-module--footerRow--2YZDK";
export var footerCol = "default-module--footerCol--1S7gD";