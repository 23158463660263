import React, { useState } from "react";
import { createPortal } from "react-dom";

import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Hamburger from "hamburger-react";

import * as styles from "./default.module.scss";
import "../index.scss";

const navElements = [
    <Link to={"/features"}>Features</Link>,
    <Link to={"/pricing"}>Pricing</Link>,
    <Link to={"/about"}>About</Link>,
];

const NavPopout = ({ active, setActive }) => {
    let topOffset =
        typeof window === "undefined" ? 0 : -Math.min(0, window.scrollY - 31);

    const onClick = () => {
        setActive(false);
    };

    if (typeof document !== "undefined")
        return createPortal(
            <div
                className={
                    styles.popoutNav + " " + (active ? styles.active : "")
                }
                onClick={onClick}
                role="dialog"
            >
                <Link
                    className={styles.logo}
                    style={{ transform: "translate(0, " + topOffset + "px)" }}
                    to={"/"}
                >
                    <StaticImage
                        alt={"Logo"}
                        src={"../images/Minerva.png"}
                        width={50}
                        className={styles.img}
                    />
                </Link>
                <div
                    className={styles.hamburgerWrapper}
                    style={{ transform: "translate(0, " + topOffset + "px)" }}
                >
                    <Hamburger
                        onToggle={() => setActive(!active)}
                        toggled={active}
                        label="Close Mobile Menu"
                    />
                </div>
                <nav>{navElements}</nav>
            </div>,
            document.body
        );

    return <></>;
};

const Layout = ({ children, noSpaceBehindNav }) => {
    const [popoutActive, setPopoutActive] = useState(false);

    return (
        <div className={styles.layout}>
            <nav className={styles.navigation}>
                <Link to={"/"} className={styles.logo}>
                    <StaticImage
                        alt={"Logo"}
                        src={"../images/Minerva.png"}
                        width={50}
                        className={styles.img}
                    />
                    <div className={styles.logoTextStack}>
                        <span className={styles.logoImpact}>MinervaTools</span>
                        <span>World management made easy.</span>
                    </div>
                </Link>

                <div className={styles.navLinks}>{navElements}</div>
                <div className={styles.hamburgerWrapper}>
                    <Hamburger
                        onToggle={() => setPopoutActive(!popoutActive)}
                        toggled={popoutActive}
                        label="Close Mobile Menu"
                    />
                </div>

                <NavPopout setActive={setPopoutActive} active={popoutActive} />
            </nav>
            <main style={{ marginTop: noSpaceBehindNav ? "-90px" : "0" }}>
                {children}
            </main>
            <footer className={styles.footer}>
                <div className={styles.footerRow}>
                    <div className={styles.footerCol}>
                        <span>Legal Information</span>
                        <Link to={"/legal/imprint"}>Imprint</Link>
                        <Link to={"/legal/privacy"}>Privacy Policy</Link>
                        <Link to={"/legal/tos"}>Terms of Service</Link>
                    </div>
                    <div className={styles.footerCol}>
                        <span>Product</span>
                        <Link to={"/features"}>Features</Link>
                        <Link to={"/pricing"}>Pricing</Link>
                        {/*<Link to={"/roadmap"}>Roadmap</Link>*/}
                    </div>
                    <div className={styles.footerCol}>
                        <span>Support</span>
                        <a href={"https://docs.minervatools.net"}>
                            Documentation
                        </a>
                        <a href={"https://minervatools.statuspage.io/"}>
                            Status
                        </a>
                        <a
                            href={
                                "https://github.com/MinervaTools/MinervaTools/issues"
                            }
                        >
                            GitHub Issue Tracker
                        </a>
                        <Link to={"/legal/imprint"}>Contact</Link>
                    </div>
                </div>
                <div className={styles.footerRow}>
                    <span>
                        &copy; {new Date().getFullYear()}, MinervaTools
                        <br />
                        <small>
                            "Minecraft" and the Minecraft Logo are trademarks of
                            Microsoft Corporation.
                            <br />
                            Microsoft Corporation is not affiliated with
                            MinervaTools.
                        </small>
                    </span>
                </div>
            </footer>
        </div>
    );
};

export default Layout;
